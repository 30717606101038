import { authStore } from '@/stores/auth';
import api from '@/utils/api-services';
import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/register',
    name: 'register',
    component: () => import('@/components/auth/register.vue'),
    beforeEnter: (to, from, next) => {
      const auth = authStore();
      if (auth.isLoaded && auth.isAuthenticated) next('/');
      else next();
    },
    meta: {
      title: `${domainTitle} - Create an Account`
    }
  },

  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/email/verify',
    name: 'verify',
    component: () => import('@/components/auth/verify-email.vue'),
    beforeEnter: (to, from, next) => {
      const auth = authStore();
      if (auth.user?.email_verified_at != null) next('/');
      else next();
    },
    meta: {
      title: `Email Verification - ${domainTitle}`
    },
    children: [
      {
        path: ':applicationId/:hash',
        beforeEnter: async (to, from, next) => {
          try {
            const confirmation = await api.confirmRegistration(
              to.params.applicationId,
              to.params.hash,
              to.query.expires,
              to.query.signature
            );

            const auth = authStore();
            auth.resetUser();
            next(confirmation.data.redirect_to);
          } catch (error) {
            if (401 === error?.response?.status) {
              console.log(error.response);
              //todo: add error toaster here
            }
            next('/email/verify');
          }
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/auth/login.vue'),
    beforeEnter: (to, from, next) => {
      const auth = authStore();
      if (auth.isLoaded && auth.isAuthenticated) next('/');
      else next();
    },
    meta: {
      title: `${domainTitle} - Login`
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      try {
        const confirmation = await api.logoutUser();
        const auth = authStore();
        auth.resetUser();
        // todo need to reset all stores here
      } catch (error) {
        if (401 === error?.response?.status) {
          console.log(error.response);
          //todo: add error toaster here
        }
      }
      next('/login');
    }
  },
  {
    path: '/password/forgot',
    name: 'forgetPassword',
    component: () => import('@/components/auth/forgot-password.vue'),
    meta: {
      title: `${domainTitle} - Forgot Password`
    }
  },
  {
    path: '/password/reset/:token',
    name: 'resetPassword',
    component: () => import('@/components/auth/reset-password.vue'),
    meta: {
      title: `${domainTitle} - Reset Password`
    }
  },
  {
    path: '/email/verify/resent',
    name: 'resendExpiredVerification',
    component: () => import('@/components/auth/expired-verification.vue'),
    meta: {
      title: `${domainTitle} - Verify Email`
    }
  },
  // 2FA setup
  {
    path: '/2fa/setup',
    name: 'setup2fa',
    component: () => import('@/components/auth/setup-2fa.vue'),
    meta: {
      title: `${domainTitle} - Setup 2FA`
    },
    beforeEnter: (to, from, next) => {
      const auth = authStore();
      if (auth.isLoaded && auth.user['2faEnabled']) next('/');
      else next();
    },
  },

];
