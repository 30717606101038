import Vue from 'vue';
Vue.config.devtools = true;
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api';
import VueTooltip from 'v-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import router from './router';

import './assets/base.css';
import './assets/tailwind.css';
import './config/plugins';
import { BrowserTracing } from '@sentry/tracing';
import http from './config/services/http';

// Global components
import AutoLogout from '@/components/common/auto-logout/index.vue';
import Sardine from '@/components/common/sardine.vue';
import VueRouter from "vue-router";

// To be used in desired components by inserting this - <auto-logout />
Vue.component('AutoLogout', AutoLogout);

// To be used in desired components by inserting this - <sardine />
Vue.component('Sardine', Sardine);

Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);

Vue.use(VueTooltip);
VueTooltip.options.defaultTemplate =
  '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VueTooltip.options.defaultArrowSelector =
  '.tooltip-vue-arrow, .tooltip-vue__arrow';
VueTooltip.options.defaultInnerSelector =
  '.tooltip-vue-inner, .tooltip-vue__inner';

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#3A71E6',
  heightAuto: false
});

// Helpscout Beacon Installation
!(function (e, t, n) {
  function a() {
    let e = t.getElementsByTagName("script")[0],
      n = t.createElement("script");
    n.type = "text/javascript";
    n.async = !0;
    n.src = "https://beacon-v2.helpscout.net";
      e.parentNode.insertBefore(n, e)
  }
  if (
    ((e.Beacon = n = function (t, n, a) {
      e.Beacon.readyQueue.push({ method: t, options: n, data: a })
    }),
    (n.readyQueue = []),
      "complete" === t.readyState)
  )
    return a();
  e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
})(window, document, window.Beacon || function () { });

router.beforeEach((to, from, next) => {
  if ((from.fullPath.includes('/login') && to.fullPath.includes('/apply')) || to.fullPath.includes('/apply')) {
    window.Beacon('destroy');
  } else {
    window.Beacon('config', {
      color: '#354CEF',
      display: {
        iconImage: 'message',
        text: 'Support',
        horizontalOffset: 20,
        verticalOffset: 20,
        position: 'right'
      },
    });
    window.Beacon('init', process.env.VUE_APP_HELPSCOUT_BEACON_ID);
  }
  next();
});

Vue.prototype.$http = http;
Vue.config.productionTip = false;

// Using a conditional to ensure it doesn't load during E2E tests
if (!window.isReflectTest) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          ...process.env.VUE_APP_SENTRY_ORIGINS.split(','),
          /^\//
        ]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications']
  });
}

new Vue({
  router,
  validations: {},
  pinia: createPinia(),
  render: (h) => h(App)
}).$mount('#app');
