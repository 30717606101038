import * as configcat from 'configcat-js';

// ConfigCat creates a single client per SDK https://configcat.com/docs/sdk-reference/js/#creating-the-configcat-client, no need to cache on our end
export const getClient = (customerId) => {
  return configcat.getClient(
    process.env.VUE_APP_CONFIGCAT_SDK_KEY,
    configcat.PollingMode.LazyLoad,
    {
      // increase cache TTL to limit config.json downloads as we're in Free plan with a maximum of 5M/month
      cacheTimeToLiveSeconds:
        process.env.VUE_APP_ENVIRONMENT === 'prod' ? 120 : 60,
      logger: configcat.createConsoleLogger(configcat.LogLevel.Warn),
      defaultUser: new configcat.User(String(customerId))
    }
  );
};

export const disposeClient = () => {
  configcat.disposeAllClients();
};

export const flagKeys = {
  rewardsEngineUIEnabled: 'rewardsEngineUIEnabled',
  payablesUIEnabled: 'payablesUIEnabled'
};
